import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { CalendarIcon } from '@heroicons/react/24/outline';

const WEEKDAY_NAMES = {
  'Sunday': 'Sonntag',
  'Monday': 'Montag',
  'Tuesday': 'Dienstag',
  'Wednesday': 'Mittwoch',
  'Thursday': 'Donnerstag',
  'Friday': 'Freitag',
  'Saturday': 'Samstag'
};

const MONTH_NAMES = [
  "Januar", "Februar", "März", "April", "Mai", "Juni",
  "Juli", "August", "September", "Oktober", "November", "Dezember"
];

const Dienstplan = () => {
  const [currentDate, setCurrentDate] = useState(new Date(2025, 0, 1));
  const [rosterData, setRosterData] = useState([]);
  const [actualTimeEntries, setActualTimeEntries] = useState([]);
  const [annualPlanDates, setAnnualPlanDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('accessToken');
  const currentYear = 2025;

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  useEffect(() => {
    if (employee) {
      fetchScheduleData();
    }
  }, [currentDate, employee]);

  const fetchEmployeeData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_EMPLOYEE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({ token }),
        redirect: 'follow'
      });
      const result = await response.json();
      
      if (!result.error) {
        setEmployee(result);
      } else {
        setError('Fehler beim Laden der Mitarbeiterdaten');
      }
    } catch (error) {
      setError('Fehler beim Laden der Mitarbeiterdaten');
    }
  };

  const fetchScheduleData = async () => {
    setLoading(true);
    setError(null);

    try {
      const timesResponse = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          token,
          email: employee.email
        })
      });
      const timesData = await timesResponse.json();

      const annualPlanResponse = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getAnnualPlanVersion2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({ token })
      });
      const annualPlanData = await annualPlanResponse.json();
      console.log('Annual Plan Dates Response:', annualPlanData);

      setActualTimeEntries(timesData.error ? [] : timesData);
      
      if (annualPlanData && Array.isArray(annualPlanData)) {
        console.log('Setting annual plan data as array:', annualPlanData);
        setAnnualPlanDates(annualPlanData);
      } else if (annualPlanData && annualPlanData.annualPlanTimes && Array.isArray(annualPlanData.annualPlanTimes)) {
        console.log('Setting annual plan data from annualPlanTimes:', annualPlanData.annualPlanTimes);
        setAnnualPlanDates(annualPlanData.annualPlanTimes);
      } else {
        console.error('Annual plan data is not in expected format:', annualPlanData);
        setAnnualPlanDates([]);
      }
    } catch (error) {
      setError('Fehler beim Laden der Daten');
    }
    
    setLoading(false);
  };

  const getWorkHours = (date, isActual = false) => {
    if (isActual) {
      const formattedDate = date.toLocaleDateString('en-CA');
      const timeEntries = actualTimeEntries.filter(entry => 
        entry.date === formattedDate && 
        entry.status === 'approved'
      );
      
      if (!timeEntries.length) return null;

      return timeEntries.map(entry => ({
        start: entry.startTime,
        end: entry.endTime
      }));
    }

    if (!Array.isArray(annualPlanDates)) {
      console.error('annualPlanDates is not an array:', annualPlanDates);
      return null;
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    
    try {
      const annualPlanEntries = annualPlanDates.filter(entry => {
        if (!entry || !entry.start) return false;
        const entryDate = entry.start.split(' ')[0];
        console.log(`Comparing dates: calendar date=${formattedDate}, entry date=${entryDate}`);
        return entryDate === formattedDate;
      });

      if (annualPlanEntries.length > 0) {
        console.log(`Found ${annualPlanEntries.length} entries for ${formattedDate}:`, annualPlanEntries);
        return annualPlanEntries.map(entry => {
          try {
            const startParts = entry.start.split(' ');
            const endParts = entry.end.split(' ');
            
            const startTime = new Date(`${startParts[0]}T${startParts[1]}`);
            const endTime = new Date(`${endParts[0]}T${endParts[1]}`);
            const hoursDiff = Math.round((endTime - startTime) / (1000 * 60 * 60));
            
            return {
              isAnnualPlan: true,
              hours: hoursDiff,
              start: entry.start,
              end: entry.end
            };
          } catch (error) {
            console.error('Error processing annual plan entry:', error, entry);
            return null;
          }
        }).filter(Boolean);
      }
    } catch (error) {
      console.error('Error in getWorkHours:', error);
    }

    return null;
  };

  const renderCalendarDay = (date, isToday) => {
    let plannedHours = null;
    let actualHours = null;
    
    try {
      plannedHours = getWorkHours(date, false);
      actualHours = getWorkHours(date, true);
    } catch (error) {
      console.error('Error getting work hours:', error);
    }
    
    return (
      <td key={date.getDate()} className={`p-2 border relative group ${isToday ? 'bg-blue-50' : 'bg-white'}`}>
        <div className="min-h-[6rem]">
          <div className="font-bold mb-1">{date.getDate()}</div>
          
          <div className="text-xs">
            {plannedHours && plannedHours.length > 0 && (
              <div>
                <div className="text-gray-600">Geplant:</div>
                {plannedHours[0].isAnnualPlan ? (
                  <div className="text-blue-600">{plannedHours[0].hours} Stunden</div>
                ) : (
                  <div className="text-blue-600">{plannedHours[0].start} - {plannedHours[0].end}</div>
                )}
              </div>
            )}
            {actualHours && actualHours.length > 0 && (
              <div className="mt-1">
                <div className="text-gray-600">Gearbeitet:</div>
                <div className="text-green-600">{actualHours[0].start} - {actualHours[0].end}</div>
              </div>
            )}
            {((plannedHours && plannedHours.length > 1) || (actualHours && actualHours.length > 1)) && (
              <div className="text-gray-400 text-center mt-1">
                • • •
              </div>
            )}
          </div>

          {((plannedHours && plannedHours.length > 1) || (actualHours && actualHours.length > 1)) && (
            <div className="hidden group-hover:block absolute top-0 left-0 w-[200%] z-10 bg-white shadow-lg rounded-md p-2 border">
              <div className="font-bold mb-2">{date.getDate()}. {MONTH_NAMES[date.getMonth()]}</div>
              {plannedHours && plannedHours.map((hours, index) => (
                <div key={`planned-${index}`} className="text-xs mb-1">
                  <div className="text-gray-600">Geplant:</div>
                  {hours.isAnnualPlan ? (
                    <div className="text-blue-600">{hours.hours} Stunden (Jahresplan)</div>
                  ) : (
                    <div className="text-blue-600">{hours.start} - {hours.end}</div>
                  )}
                </div>
              ))}
              {actualHours && actualHours.map((hours, index) => (
                <div key={`actual-${index}`} className="text-xs mt-1">
                  <div className="text-gray-600">Gearbeitet:</div>
                  <div className="text-green-600">{hours.start} - {hours.end}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </td>
    );
  };

  const renderCalendar = () => {
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const today = new Date().toDateString();
    const weeks = [];
    let days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<td key={`empty-${i}`} className="p-2 border bg-gray-50" />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      days.push(renderCalendarDay(date, date.toDateString() === today));

      if ((firstDayOfMonth + day) % 7 === 0 || day === daysInMonth) {
        while ((firstDayOfMonth + day) % 7 !== 0) {
          days.push(<td key={`empty-end-${day}`} className="p-2 border bg-gray-50" />);
          day++;
        }
        weeks.push(<tr key={weeks.length}>{days}</tr>);
        days = [];
      }
    }

    return weeks;
  };

  return (
    <div className="p-8">
      <div className="border-b border-gray-200 pb-5 mb-12 pt-12">
        <h1 className="text-4xl font-bold text-gray-900">Dienstplan</h1>
      </div>
      
      <div className="flex justify-between items-center mb-4">
        <button 
          onClick={() => {
            const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
            if (newDate.getFullYear() >= currentYear) {
              setCurrentDate(newDate);
            }
          }}
          disabled={currentDate.getMonth() === 0 && currentDate.getFullYear() === currentYear}
          className={`p-2 rounded hover:bg-gray-100 ${currentDate.getMonth() === 0 && currentDate.getFullYear() === currentYear ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
        
        <h2 className="text-xl font-semibold">
          {MONTH_NAMES[currentDate.getMonth()]} {currentDate.getFullYear()}
        </h2>
        
        <button 
          onClick={() => {
            const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
            if (newDate.getFullYear() <= currentYear && newDate.getMonth() <= 11) {
              setCurrentDate(newDate);
            }
          }}
          disabled={currentDate.getMonth() === 11 && currentDate.getFullYear() === currentYear}
          className={`p-2 rounded hover:bg-gray-100 ${currentDate.getMonth() === 11 && currentDate.getFullYear() === currentYear ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <ChevronRightIcon className="h-5 w-5" />
        </button>
      </div>

      {!annualPlanDates.length && !loading && !error && (
        <div className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded-md text-yellow-700">
          <p>Kein Jahresplan für diesen Zeitraum verfügbar. Sie können trotzdem Ihre gearbeiteten Zeiten sehen.</p>
        </div>
      )}

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
        </div>
      ) : error ? (
        <div className="flex flex-col items-center justify-center h-64 text-gray-500">
          <CalendarIcon className="h-12 w-12 mb-4" />
          <p>{error}</p>
        </div>
      ) : (
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="p-2 border bg-gray-50">So</th>
              <th className="p-2 border bg-gray-50">Mo</th>
              <th className="p-2 border bg-gray-50">Di</th>
              <th className="p-2 border bg-gray-50">Mi</th>
              <th className="p-2 border bg-gray-50">Do</th>
              <th className="p-2 border bg-gray-50">Fr</th>
              <th className="p-2 border bg-gray-50">Sa</th>
            </tr>
          </thead>
          <tbody>
            {renderCalendar()}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Dienstplan; 