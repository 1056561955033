import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { DocumentIcon, QrCodeIcon, UserIcon, PlusIcon, EllipsisVerticalIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

import FileUploadDialog from './FileUploadDialog';
import QRCodeDialog from './QRCodeDialog';
import EditEmployeeDetails from './EditEmployeeDetails';
import ProjectSelectionDialog from './ProjectSelectionDialog';
import EmployeeSelectionDialog from './EmployeeSelectionDialog';

const EmployeeDetails = ({ employee, allEmployees, setEmployeeList, onBack, user }) => {
  const [currentEmployee, setCurrentEmployee] = useState(employee);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [QRValue, setQRValue] = useState("");
  const [showEditPage, setShowEditPage] = useState(false);
  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [showEmployeeDialog, setShowEmployeeDialog] = useState(false);
  const [showMenu, setShowMenu] = useState(null);
  const [updatedToken, setUpdatedToken] = useState("");

  const token = localStorage.getItem('accessToken');

  const isBRAdmin = localStorage.getItem('isBRAdmin') === 'true';

  const employeeProjects = Object.entries(currentEmployee.projects).length ? Object.entries(currentEmployee.projects) : [];

  const upperEmployeeIds = currentEmployee.upperEmployees || [];
  const upperEmployees = allEmployees.filter(emp => upperEmployeeIds.includes(emp.employeeId));

  const excelSerialDateToJSDate = (serial) => {
    const utcDays = Math.floor(serial - 25569);
    const utcValue = utcDays * 86400;
    const dateInfo = new Date(utcValue * 1000);

    return new Date(dateInfo.getFullYear(), dateInfo.getMonth(), dateInfo.getDate());
  }

  const formatDate = (date) => {
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    return `${d < 10 ? '0' : ''}${d}/${m < 10 ? '0' : ''}${m}/${y}`;
  }

  const handleUploadDialogOpen = () => {
    setShowUploadDialog(true);
  };

  const handleUploadDialogClose = () => {
    setShowUploadDialog(false);
  };

  const handleRemoveProject = (projectId) => {
    handleDeleteProject(projectId);
    setShowMenu(null);
  };

  const handleQRCodeDialogOpen = async () => {
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/generateKey', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          email: currentEmployee.email
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      const automatedLoginUrl = `${data.secretKey}`;
      setUpdatedToken(data.secretKey)
      setQRValue(automatedLoginUrl);
    } catch (error) {
      console.error('Error fetching the user token:', error);
    }
  };

  const handleShowExistingQRCode = async () => {
    try {
      setQRValue(updatedToken || currentEmployee.secretKey);
    } catch (error) {
      console.error('Error fetching the existing QR code key:', error);
    }
  };

  const handleQRCodeDialogClose = () => {
    setQRValue("");
  };

  const handleEditPageOpen = () => {
    setShowEditPage(true);
  };

  const handleEditPageClose = async (updatedEmployee, home) => {
    if (updatedEmployee && home) {
      const updatedEmployees = await allEmployees.filter(employee => employee.employeeId !== updatedEmployee.employeeId);
      setEmployeeList(updatedEmployees)
      onBack()
    }
    if (updatedEmployee) {
      setCurrentEmployee(updatedEmployee);
    }
    setShowEditPage(false);
  };

  const handleProjectDialogOpen = () => {
    setShowProjectDialog(true);
  };

  const handleProjectDialogClose = () => {
    setShowProjectDialog(false);
  };

  const handleProjectSave = (selectedProjects) => {
    const updatedProjects = { ...currentEmployee.projects, ...selectedProjects };
    updateEmployeeProjects(updatedProjects);
  };

  const handleDeleteProject = (projectId) => {
    const updatedProjects = { ...currentEmployee.projects };
    delete updatedProjects[projectId];
    updateEmployeeProjects(updatedProjects);
  };

  const handleDownloadDienstplan = async () => {
    const url = 'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/downloadRoster';
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({
        token
      }),
    };
  
    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();
      console.log('Download Roster result:', result);
  
      if (Array.isArray(result)) {
        const ws_data = [
          ["Name", "Personalnummer", "Datum", "Start", "Ende", "Pausen (min)", "Standort"]
        ];
  
        result.forEach(employee => {
          if (employee.schedule && employee.schedule.length > 0) {
            employee.schedule.forEach(entry => {
              ws_data.push([
                employee.name,
                employee.employeeId,
                entry.Datum,
                entry.Start,
                entry.Ende,
                entry["Pausen (min)"],
                entry.Standort
              ]);
            });
          }
        });
  
        const ws = XLSX.utils.aoa_to_sheet(ws_data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Dienstplan");
  
        XLSX.writeFile(wb, `Dienstpläne.xlsx`);
      }
    } catch (error) {
      console.error('Error downloading Dienstplan:', error);
    }
  };

  const updateEmployeeProjects = async (updatedProjects) => {
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeEmployeeProjects', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          employeeId: currentEmployee.employeeId,
          projects: updatedProjects
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.error) {
        console.error('Error updating projects:', result.error);
      } else {
        setCurrentEmployee(prevState => ({
          ...prevState,
          projects: updatedProjects
        }));
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getUserData = async () => {
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getUserData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: employee.email
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.error) {
        console.error('Error updating projects:', result.error);
      } else {
        console.log(result)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleMenuToggle = (id) => {
    setShowMenu(showMenu === id ? null : id);
  };

  const handleRemoveAdmin = async (adminId) => {
    try {
      const updatedUpperEmployees = currentEmployee.upperEmployees.filter(id => id !== adminId);
  
      const admin = allEmployees.find(emp => emp.employeeId === adminId);
      const updatedSubEmployees = admin.subEmployees.filter(id => id !== currentEmployee.employeeId);
  
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeSubEmployees', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          employeeId: currentEmployee.employeeId,
          upperEmployees: updatedUpperEmployees,
          targetEmployeeId: adminId,
          subEmployees: updatedSubEmployees,
          removeFromSubEmployees: true,
        }),
      });
  
      const result = await response.json();
  
      if (result.success) {
        setCurrentEmployee(prevState => ({
          ...prevState,
          upperEmployees: updatedUpperEmployees,
        }));
  
        setEmployeeList(prevList =>
          prevList.map(emp => {
            if (emp.employeeId === currentEmployee.employeeId) {
              return { ...emp, upperEmployees: updatedUpperEmployees };
            }
            return emp;
          })
        );
  
        console.log('Admin successfully removed from upperEmployees');
      } else {
        console.error('Failed to remove admin:', result.error);
      }
    } catch (error) {
      console.error('Error removing admin:', error);
    }
    setShowMenu(null);
  };

  const handleAddAdminClick = () => {
    setShowMenu(null);
    setShowEmployeeDialog(true);
  };

  const handleAddAdmin = async (employee) => {
    try {
        const subEmployees = Array.isArray(employee.subEmployees) ? employee.subEmployees : [];
        const upperEmployees = Array.isArray(currentEmployee.upperEmployees) ? currentEmployee.upperEmployees : [];

        const updatedSubEmployees = [...new Set([...subEmployees, currentEmployee.employeeId])]
            .filter(empId => empId !== employee.employeeId);

        const updatedUpperEmployees = [...new Set([...upperEmployees, employee.employeeId])]
            .filter(empId => empId !== currentEmployee.employeeId);

        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeSubEmployees', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token,
                employeeId: currentEmployee.employeeId,
                upperEmployees: updatedUpperEmployees,
                targetEmployeeId: employee.employeeId,
                subEmployees: updatedSubEmployees,
            }),
        });

        const result = await response.json();
        if (result.success) {
            setCurrentEmployee(prevState => ({
                ...prevState,
                upperEmployees: updatedUpperEmployees,
            }));

            console.log('Successfully updated upperEmployees and subEmployees.');
        } else {
            console.error('Failed to update upperEmployees and subEmployees:', result.error);
        }
    } catch (error) {
        console.error('Error updating upperEmployees and subEmployees:', error);
    }
};

  if (showEditPage) {
    return <EditEmployeeDetails employee={currentEmployee} onBack={handleEditPageClose} user={user} />;
  }

  return (
    <div className="bg-gray-100 w-full max-w-screen-xl mx-auto">
      {showUploadDialog && <FileUploadDialog onClose={handleUploadDialogClose} employee={employee} />}
      {QRValue && <QRCodeDialog onClose={handleQRCodeDialogClose} token={QRValue} firstName={currentEmployee.firstName} lastName={currentEmployee.lastName} employeeId={currentEmployee.employeeId} setUpdatedToken />}
      {showProjectDialog && (
        <ProjectSelectionDialog
          onClose={handleProjectDialogClose}
          onSave={handleProjectSave}
          token={token}
          existingProjects={currentEmployee.projects}
        />
      )}
      {showEmployeeDialog && (
        <EmployeeSelectionDialog
          onClose={() => setShowEmployeeDialog(false)}
          onSave={handleAddAdmin}
          allEmployees={allEmployees}
        />
      )}

      <header className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">
            {currentEmployee?.firstName} {currentEmployee?.lastName}
          </h1>
          <p className="text-sm text-gray-600">
            <span
              className="underline cursor-pointer"
              onClick={onBack}
            >
              Alle Mitarbeiter
            </span> &gt; {currentEmployee?.firstName} {currentEmployee?.lastName}
          </p>
        </div>
        {!isBRAdmin && (
          <button
            onClick={handleEditPageOpen}
            className="bg-[#E8EDFF] text-[#0000FF] py-2 px-4 rounded-lg"
          >
            Mitarbeiteterdetails Bearbeiten
          </button>
        )}
      </header>

      <div className="flex justify-between mb-[46px] gap-4">
        <div className="bg-white shadow-md rounded-xl w-1/2 p-[16px] flex">
          <DocumentIcon className="h-5 w-5 mr-2 text-[#A7B3C4] self-start mt-1" />
          <div>
            <h2 onClick={getUserData} className="text-xl font-bold text-[#A7B3C4]">Dienstpläne</h2>
            <p className="text-sm text-[#A7B3C4] mb-4">Letzte Aktualisierung: 23.05.2023</p>
            <div className='border-t' />
            <div className="flex mt-[16px]">
              {!isBRAdmin && (
                <button onClick={handleUploadDialogOpen} className="bg-[#0000FF] text-white py-2 px-4 rounded-lg flex items-center mr-2">
                  <PlusCircleIcon className="h-5 w-5 mr-2" />
                  Neuer Dienstplan
                </button>
              )}
              <button onClick={handleDownloadDienstplan} className="bg-[#E8EDFF] text-[#0000FF] py-2 px-4 rounded-lg flex items-center">
                Download alter Dienstplan
                <ArrowDownTrayIcon className="h-5 w-5 ml-2" />
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-xl w-1/2 p-[16px] flex">
          <QrCodeIcon className="h-5 w-5 mr-2 text-[#A7B3C4] self-start mt-1" />
          <div>
            <h2 className="text-xl font-bold text-[#A7B3C4]">QR-Code</h2>
            <p className="text-sm text-[#A7B3C4] mb-4">Letzte Aktualisierung: 23.05.2023</p>
            <div className='border-t' />
            <div className="flex mt-[16px]">
              {!currentEmployee.isBRAdmin && (
                <>
                  <button onClick={handleShowExistingQRCode} className="bg-[#0000FF] text-white py-2 px-4 rounded-lg flex items-center mr-2">
                    QR-Code anzeigen
                  </button>
                  {!isBRAdmin && (
                    <button onClick={handleQRCodeDialogOpen} className="bg-[#E8EDFF] text-[#0000FF] py-2 px-4 rounded-lg flex items-center mr-2">
                      QR-Code generieren
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='mb-[46px]'>
        <h2 className="text-[20px] font-bold text-[#101828] mb-4">Persönliche Informationen</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Vorname</label>
            <p className='font-[500]'>{currentEmployee?.firstName}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Nachname</label>
            <p className='font-[500]'>{currentEmployee?.lastName}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Jobtitel</label>
            <p className='font-[500]'>{currentEmployee?.jobTitle}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Mitarbeiter-ID</label>
            <p className='font-[500]'>{currentEmployee?.employeeId}</p>
          </div>
          {/* <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Arbeitszeitmodell</label>
            <p className='font-[500]'>{currentEmployee?.timeModel && typeof currentEmployee.timeModel === 'string' ? currentEmployee.timeModel : 'Keine Angaben' }</p>
          </div> */}
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Wochenarbeitsstunden</label>
            <p className='font-[500]'>{currentEmployee?.hours_week && typeof currentEmployee.hours_week === 'string' ? currentEmployee.hours_week : '0'}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Monatsarbeitsstunden</label>
            <p className='font-[500]'>{currentEmployee?.hours_month && typeof currentEmployee.hours_month === 'string' ? currentEmployee.hours_month : '0'}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Urlaubstage</label>
            <p className='font-[500]'>{currentEmployee?.holidayValue && typeof currentEmployee.holidayValue === 'string' ? currentEmployee.holidayValue : '0'}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Vertraglich geschuldete durchschnittliche Arbeitszeit</label>
            <p className='font-[500]'>{typeof currentEmployee?.contractualAverageWorkingTime === 'number' ? currentEmployee.contractualAverageWorkingTime : '0'}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Vertragsmodell</label>
            <p className='font-[500]'>{typeof currentEmployee?.contractModel === 'string' && currentEmployee.contractModel ? currentEmployee.contractModel : 'Nicht angegeben'}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Startdatum, aktueller Arbeitsvertrag</label>
            <p className='font-[500]'>{currentEmployee?.contractStartDate && typeof currentEmployee.contractStartDate !== 'object' ? new Date(currentEmployee.contractStartDate).toLocaleDateString('de-DE') : 'Nicht angegeben'}</p>
          </div>
        </div>
      </div>

      <div className='mb-[46px]'>
        <h2 className={`text-[24px] font-bold ${currentEmployee?.isAdmin ? "text-[#0000FF]" : "text-[#101828]"}`}>
          {currentEmployee?.isSuperAdmin ? 'Super Adminrechte vorhanden' : currentEmployee?.isAdmin ? 'Adminrechte vorhanden' : 'Keine Adminrechte vorhanden'}
        </h2>
        <p className="text-sm text-gray-600">
          Admins können Mitarbeiter und Zeiten verwalten. Für zugewiesene Standorte und Projekte hat der Mitarbeiter die Berechtigung, Zeiten zu verwalten.
          Die Zeiten aller Mitarbeiter, die zu diesen Projekten gehören, können so durch den Mitarbeiter verwaltet werden.
        </p>
      </div>

      <div className="bg-white shadow-md rounded-xl mb-[46px] p-[16px]">
        <h2 className="text-[24px] font-bold text-[#A7B3C4] pb-[16px] border-b">Zugehörigkeit</h2>
        <p className="text-sm text-[#787D87] py-[16px]">{currentEmployee.firstName}s Zeiten können von folgenden Personen verwaltet werden:</p>
        <ul>
          {upperEmployees.map(upperEmp => (
            <li key={upperEmp._id} className="text-[14px] font-[500] leading-[20px] mb-[12px] px-[8px] flex items-center justify-between">
              <div className="flex items-center">
                <UserIcon className="h-5 w-5 mr-2" />
                {upperEmp.firstName} {upperEmp.lastName}
              </div>
              <div className="relative">
                {!isBRAdmin && (
                  <EllipsisVerticalIcon
                    className='h-[25px] w-[25px] cursor-pointer'
                    onClick={() => handleMenuToggle(upperEmp._id)}
                  />
                )}
                {showMenu === upperEmp._id && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                    <button
                      onClick={() => handleRemoveAdmin(upperEmp.employeeId)}
                      className="block w-full text-left px-4 py-2 text-sm text-red-700 "
                    >
                      Entfernen
                    </button>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
        <div className="flex items-center px-[8px] pt-[12px]">
          {
            user?.isSuperAdmin &&
            <>
              <PlusIcon className="h-5 w-5 mr-2 text-[#A7B3C4]" />
              <button onClick={handleAddAdminClick} className="text-[#787D87]">hinzufügen</button>
            </>
          }
        </div>
      </div>

      <div className="bg-white shadow-md rounded-xl mb-[46px] p-[16px]">
        <h2 className="text-[24px] font-bold text-[#A7B3C4] pb-[16px] border-b">Zugewiesene Standorte</h2>
        <ul>
          {employeeProjects.map(([projectId, projectName], index) => (
            <li key={index} className="px-[8px] py-[12px] flex items-center justify-between">
              {projectName}
              <div className="relative">
                {!isBRAdmin && (
                  <EllipsisVerticalIcon
                    className='h-[25px] w-[25px] cursor-pointer'
                    onClick={() => handleMenuToggle(projectId)}
                  />
                )}
                {showMenu === projectId && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                    <button
                      onClick={() => handleRemoveProject(projectId)}
                      className="block w-full text-left px-4 py-2 text-sm text-red-700 "
                    >
                      Entfernen
                    </button>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
        <div className="flex items-center px-[8px] pt-[12px]">
          {!isBRAdmin && (
            <>
              <PlusIcon className="h-5 w-5 mr-2 text-[#A7B3C4]" />
              <button onClick={handleProjectDialogOpen} className="text-[#787D87]">hinzufügen</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;