import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import * as Realm from "realm-web";
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

const Export = ({ setLoggedIn }) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uniqueMonths, setUniqueMonths] = useState([]);
  const [approvedTimesData, setApprovedTimesData] = useState({});
  const [annualPlans, setAnnualPlans] = useState({});
  const [holidayPlans, setHolidayPlans] = useState({});
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [timeClasses, setTimeClasses] = useState([]);

  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const app = new Realm.App({ id: "data-ywwpoom" });
  const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    const initialize = async () => {
      const isValid = await validateToken(token);
      if (!isValid) {
        if (stayLoggedIn) {
          const refreshed = await refreshAccessToken();
          if (!refreshed) {
            setLoggedIn(false);
            navigate('/login');
          } else {
            const newToken = app.currentUser.accessToken;
            const isValidNewToken = await validateToken(newToken);
            if (!isValidNewToken) {
              setLoggedIn(false);
              navigate('/login');
            } else {
              setLoggedIn(true);
              await fetchTimeClasses();
              await fetchData();
            }
          }
        } else {
          setLoggedIn(false);
          navigate('/login');
        }
      } else {
        setLoggedIn(true);
        await fetchTimeClasses();
        await fetchData();
      }
    };

    initialize();
  }, [token, setLoggedIn, navigate, stayLoggedIn]);

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [selectedYear]);

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [selectedEmployee]);

  const fetchTimeClasses = async () => {
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimeClasses');
      const result = await response.json();
      console.log("Fetched time classes:", result);
      setTimeClasses(result || []);
    } catch (error) {
      console.error('Error fetching time classes:', error);
    }
  };

  const validateToken = (token) => {
    return new Promise((resolve, reject) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({ token: token }),
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.error) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch(error => {
            console.error('Error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };

  const refreshAccessToken = async () => {
    try {
      await app.currentUser.refreshAccessToken();
      localStorage.setItem('accessToken', app.currentUser.accessToken);
      return true;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  const fetchData = async () => {
    setLoading(true);
    console.log("Starting fetchData for year:", selectedYear);
    
    let allEmployees = [];
    try {
      const employeeListResponse = await fetch(process.env.REACT_APP_EMPLOYEELIST_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({ token }),
      redirect: 'follow'
      });
      
      const employeeListResult = await employeeListResponse.json();
      if (!employeeListResult.error) {
        allEmployees = employeeListResult.map(employee => ({
            ...employee,
          _id: employee.employeeId,
            displayName: `${employee.lastName}, ${employee.firstName}`
        }));
        console.log("Fetched all employees:", allEmployees);
        
        allEmployees.sort((a, b) => a.lastName.localeCompare(b.lastName));
        
        setEmployeeList(allEmployees);
      }
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  
    const requestBody = {
      year: selectedYear.toString(),
      export: true
    };
    
    if (selectedEmployee) {
      requestBody.employeeId = selectedEmployee;
    }
    
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify(requestBody),
      redirect: 'follow'
    };

    try {
      console.log("Fetching times data with options:", requestOptions);
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimesVersion2', requestOptions);
      const allTimes = await response.json();
      console.log("API response:", allTimes);
      
      if (!allTimes || allTimes.length === 0) {
        console.log("No times data found for the selected year");
        setApprovedTimesData([]);
        setUniqueMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
        setLoading(false);
        return;
      }
      
      setApprovedTimesData(allTimes);
      setUniqueMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
      setAnnualPlans({});
      setHolidayPlans({});
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching times data:', error);
      setUniqueMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
      setLoading(false);
    }
  };

  const handleMonthSelection = (month) => {
    let newSelectedMonths;
    if (selectedMonths.includes(month)) {
      newSelectedMonths = selectedMonths.filter(m => m !== month);
    } else {
      newSelectedMonths = [...selectedMonths, month];
    }
    setSelectedMonths(newSelectedMonths);
  };

  const handleExportMonth = async (month) => {
    console.log("Starting handleExportMonth for month:", month);
    console.log("approvedTimesData:", approvedTimesData);
    
    try {
      let filteredTimes = approvedTimesData.filter(time => {
        if (!time || !time.start) return false;
        
        const datePart = time.start.split(' ')[0];
        const date = new Date(datePart);
        const timeMonth = date.getMonth();
        const timeYear = date.getFullYear();
        
        return timeMonth === month && timeYear === selectedYear;
      });
      
      console.log("Filtered times for month:", filteredTimes);
      
      if (selectedEmployee) {
        let employeeTransactions = filteredTimes.filter(time => time.employee === selectedEmployee);
        const employee = employeeList.find(e => e.employeeId === selectedEmployee);
        const firstName = employee ? employee.firstName : "";
        const lastName = employee ? employee.lastName : "";
        
        let worksheetData = [];
        
        worksheetData.push([
          "Datum",
          "Start",
          "Ende",
          "Pause",
          "Dauer (Stunden)",
          "Typ",
          "Status"
        ]);
        
        employeeTransactions.forEach(time => {
          if (!time.start) return;
          
          const startDateTime = new Date(time.start);
          const endDateTime = new Date(time.end || time.start);
          
          const date = startDateTime.toLocaleDateString('de-DE');
          
          const startTime = startDateTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
          const endTime = endDateTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
          
          let durationMinutes = (endDateTime - startDateTime) / 60000;
          let pauseMinutes = 0;
          
          if (time.pause) {
            const pauseParts = time.pause.split(':').map(Number);
            const pauseHours = pauseParts[0];
            pauseMinutes = (pauseHours * 60) + pauseParts[1];
            durationMinutes -= pauseMinutes;
          }
          
          if (time.type) {
            const timeClass = timeClasses.find(tc => tc.name === time.type);
            if (timeClass && timeClass.factor !== undefined) {
              const factor = parseFloat(timeClass.factor);
              durationMinutes *= factor;
            }
          }
          
          const durationHours = durationMinutes / 60;
          
          let status = time.managementType || "-";
          if (status === "pending") status = "Ausstehend";
          else if (status === "denied") status = "Abgelehnt";
          else if (status === "approved") status = "Bestätigt";
          
          worksheetData.push([
            date,
            startTime,
            endTime,
            time.pause || "-",
            durationHours.toFixed(2),
            time.type || "-",
            status
          ]);
        });
        
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, `${firstName}_${lastName}_${monthNames[month]}`);
        XLSX.writeFile(workbook, `${firstName}_${lastName}_${monthNames[month]}_${selectedYear}_Export.xlsx`);
        
        return;
      }
      
      const timesByEmployee = {};
      filteredTimes.forEach(time => {
        if (!timesByEmployee[time.employee]) {
          timesByEmployee[time.employee] = [];
        }
        timesByEmployee[time.employee].push(time);
      });
      
      console.log("Times by employee for month:", timesByEmployee);
      
      let worksheetData = [];
      
      worksheetData.push([
        "Vorname", 
        "Nachname", 
        "Personalnummer", 
        "Soll Stunden", 
        "Ist Stunden", 
        "Vorläufige Jahresprognose", 
        "Gesamt AZK", 
        "Gesamt Krank", 
        "Jahresanspruch Urlaub", 
        "Gewährter Urlaub", 
        "Resturlaub"
      ]);
      
      Object.entries(timesByEmployee).forEach(([employeeId, times]) => {
        const employee = employeeList.find(e => e.employeeId === employeeId);
        const firstName = employee ? employee.firstName : "";
        const lastName = employee ? employee.lastName : "";
        
        const jahresplanTimes = times.filter(time => time.type === "Jahresplan");
        let sollStundenMinutes = 0;
        jahresplanTimes.forEach(time => {
          const startDate = new Date(time.start);
          const endDate = new Date(time.end || time.start);
          const durationMs = endDate - startDate;
          const durationMinutes = durationMs / 60000;
          sollStundenMinutes += durationMinutes;
        });
        
        const workTimes = times.filter(time => time.type !== "Jahresplan");
        let istStundenMinutes = 0;
        let specialFactorMinutes = 0;
        
        workTimes.forEach(time => {
          if ((time.managementType === 'approved' || time.managementType === 'bestätigt' || 
               time.type === 'approved' || time.type === 'bestätigt')) {
            const startDate = new Date(time.start);
            const endDate = new Date(time.end || time.start);
            let durationMinutes = (endDate - startDate) / 60000;
            
            if (time.type) {
              const timeClass = timeClasses.find(tc => tc.name === time.type);
              if (timeClass && timeClass.specialFactor === true) {
                specialFactorMinutes += durationMinutes;
                return;
              }
            }
            
            if (time.pause) {
              const pauseParts = time.pause.split(':').map(Number);
              const pauseHours = pauseParts[0];
              const pauseInMinutes = (pauseHours * 60) + pauseParts[1];
              durationMinutes -= pauseInMinutes;
            }
            
            if (time.type) {
              const timeClass = timeClasses.find(tc => tc.name === time.type);
              if (timeClass && timeClass.factor !== undefined) {
                const factor = parseFloat(timeClass.factor);
                console.log(`Applying factor ${factor} to time entry of type ${time.type}`);
                durationMinutes *= factor;
              }
            }
            
            istStundenMinutes += durationMinutes;
          }
        });
        
        const sollStunden = sollStundenMinutes / 60;
        const istStunden = istStundenMinutes / 60;
        const specialFactorHours = specialFactorMinutes / 60;
        
        const adjustedSollStunden = sollStunden - specialFactorHours;
        
        const jahresprognose = istStunden - adjustedSollStunden;
        
        const azkTimes = times.filter(time => time.type === "AZK");
        const gesamtAZK = azkTimes.length;
        
        const urlaubTimes = times.filter(time => time.type === "Urlaub");
        const gewaerterUrlaub = urlaubTimes.length;
        
        const krankTimes = times.filter(time => time.type === "Krank");
        const gesamtKrank = krankTimes.length;
        
        const jahresanspruchUrlaub = employee?.holidayValue || 0;
        
        const resturlaub = jahresanspruchUrlaub - gewaerterUrlaub;
        
        worksheetData.push([
          firstName,
          lastName,
          employeeId,
          adjustedSollStunden.toFixed(2),
          istStunden.toFixed(2),
          jahresprognose.toFixed(2),
          gesamtAZK,
          gesamtKrank,
          jahresanspruchUrlaub,
          gewaerterUrlaub,
          resturlaub
        ]);
      });
      
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `Month_${monthNames[month]}`);
      XLSX.writeFile(workbook, `Month_${monthNames[month]}_${selectedYear}_Export.xlsx`);
      
    } catch (error) {
      console.error("Error in handleExportMonth:", error);
    }
  };

  const handleExportSelectedMonths = async () => {
    console.log("Starting handleExportSelectedMonths for months:", selectedMonths);
    console.log("approvedTimesData:", approvedTimesData);
    
    if (selectedMonths.length === 0) {
      alert("Please select at least one month to export.");
      return;
    }
    
    try {
      let filteredTimes = approvedTimesData.filter(time => {
        if (!time || !time.start) return false;
        
        const datePart = time.start.split(' ')[0];
        const date = new Date(datePart);
        const timeMonth = date.getMonth();
        const timeYear = date.getFullYear();
        
        return selectedMonths.includes(timeMonth) && timeYear === selectedYear;
      });
      
      console.log("Filtered times for selected months:", filteredTimes);
      
      if (selectedEmployee) {
        let employeeTransactions = filteredTimes.filter(time => time.employee === selectedEmployee);
        const employee = employeeList.find(e => e.employeeId === selectedEmployee);
        const firstName = employee ? employee.firstName : "";
        const lastName = employee ? employee.lastName : "";
        
        let worksheetData = [];
        
        worksheetData.push([
          "Datum",
          "Start",
          "Ende",
          "Pause",
          "Dauer (Stunden)",
          "Typ",
          "Status"
        ]);
        
        employeeTransactions.forEach(time => {
          if (!time.start) return;
          
          const startDateTime = new Date(time.start);
          const endDateTime = new Date(time.end || time.start);
          
          const date = startDateTime.toLocaleDateString('de-DE');
          
          const startTime = startDateTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
          const endTime = endDateTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
          
          let durationMinutes = (endDateTime - startDateTime) / 60000;
          let pauseMinutes = 0;
          
          if (time.pause) {
            const pauseParts = time.pause.split(':').map(Number);
            const pauseHours = pauseParts[0];
            pauseMinutes = (pauseHours * 60) + pauseParts[1];
            durationMinutes -= pauseMinutes;
          }
          
          if (time.type) {
            const timeClass = timeClasses.find(tc => tc.name === time.type);
            if (timeClass && timeClass.factor !== undefined) {
              const factor = parseFloat(timeClass.factor);
              durationMinutes *= factor;
            }
          }
          
          const durationHours = durationMinutes / 60;
          
          let status = time.managementType || "-";
          if (status === "pending") status = "Ausstehend";
          else if (status === "denied") status = "Abgelehnt";
          else if (status === "approved") status = "Bestätigt";
          
          worksheetData.push([
            date,
            startTime,
            endTime,
            time.pause || "-",
            durationHours.toFixed(2),
            time.type || "-",
            status
          ]);
        });
        
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const selectedMonthNames = selectedMonths.map(m => monthNames[m]).join('_');
        
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, `${firstName}_${lastName}_Selected`);
        XLSX.writeFile(workbook, `${firstName}_${lastName}_Selected_Months_${selectedMonthNames}_${selectedYear}_Export.xlsx`);
        
        return;
      }
      
      const timesByEmployee = {};
      filteredTimes.forEach(time => {
        if (!timesByEmployee[time.employee]) {
          timesByEmployee[time.employee] = [];
        }
        timesByEmployee[time.employee].push(time);
      });
      
      console.log("Times by employee for selected months:", timesByEmployee);
      
      let worksheetData = [];
      
      worksheetData.push([
        "Vorname", 
        "Nachname", 
        "Personalnummer", 
        "Soll Stunden", 
        "Ist Stunden", 
        "Vorläufige Jahresprognose", 
        "Gesamt AZK", 
        "Gesamt Krank", 
        "Jahresanspruch Urlaub", 
        "Gewährter Urlaub", 
        "Resturlaub"
      ]);
      
      Object.entries(timesByEmployee).forEach(([employeeId, times]) => {
        const employee = employeeList.find(e => e.employeeId === employeeId);
        const firstName = employee ? employee.firstName : "";
        const lastName = employee ? employee.lastName : "";
        
        const jahresplanTimes = times.filter(time => time.type === "Jahresplan");
        let sollStundenMinutes = 0;
        jahresplanTimes.forEach(time => {
          const startDate = new Date(time.start);
          const endDate = new Date(time.end || time.start);
          const durationMs = endDate - startDate;
          const durationMinutes = durationMs / 60000;
          sollStundenMinutes += durationMinutes;
        });
        
        const workTimes = times.filter(time => time.type !== "Jahresplan");
        let istStundenMinutes = 0;
        let specialFactorMinutes = 0;
        
        workTimes.forEach(time => {
          if ((time.managementType === 'approved' || time.managementType === 'bestätigt' || 
               time.type === 'approved' || time.type === 'bestätigt')) {
            const startDate = new Date(time.start);
            const endDate = new Date(time.end || time.start);
            let durationMinutes = (endDate - startDate) / 60000;
            
            if (time.type) {
              const timeClass = timeClasses.find(tc => tc.name === time.type);
              if (timeClass && timeClass.specialFactor === true) {
                specialFactorMinutes += durationMinutes;
                return;
              }
            }
            
            if (time.pause) {
              const pauseParts = time.pause.split(':').map(Number);
              const pauseHours = pauseParts[0];
              const pauseInMinutes = (pauseHours * 60) + pauseParts[1];
              durationMinutes -= pauseInMinutes;
            }
            
            if (time.type) {
              const timeClass = timeClasses.find(tc => tc.name === time.type);
              if (timeClass && timeClass.factor !== undefined) {
                const factor = parseFloat(timeClass.factor);
                console.log(`Applying factor ${factor} to time entry of type ${time.type}`);
                durationMinutes *= factor;
              }
            }
            
            istStundenMinutes += durationMinutes;
          }
        });
        
        const sollStunden = sollStundenMinutes / 60;
        const istStunden = istStundenMinutes / 60;
        const specialFactorHours = specialFactorMinutes / 60;
        
        const adjustedSollStunden = sollStunden - specialFactorHours;
        
        const jahresprognose = istStunden - adjustedSollStunden;
        
        const azkTimes = times.filter(time => time.type === "AZK");
        const gesamtAZK = azkTimes.length;
        
        const urlaubTimes = times.filter(time => time.type === "Urlaub");
        const gewaerterUrlaub = urlaubTimes.length;
        
        const krankTimes = times.filter(time => time.type === "Krank");
        const gesamtKrank = krankTimes.length;
        
        const jahresanspruchUrlaub = employee?.holidayValue || 0;
        
        const resturlaub = jahresanspruchUrlaub - gewaerterUrlaub;
        
        worksheetData.push([
          firstName,
          lastName,
          employeeId,
          adjustedSollStunden.toFixed(2),
          istStunden.toFixed(2),
          jahresprognose.toFixed(2),
          gesamtAZK,
          gesamtKrank,
          jahresanspruchUrlaub,
          gewaerterUrlaub,
          resturlaub
        ]);
      });
      
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const selectedMonthNames = selectedMonths.map(m => monthNames[m]).join('_');
      
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `Selected_Months`);
      XLSX.writeFile(workbook, `Selected_Months_${selectedMonthNames}_${selectedYear}_Export.xlsx`);
      
    } catch (error) {
      console.error("Error in handleExportSelectedMonths:", error);
    }
  };

  const handleExportFullYear = async () => {
    console.log("Starting handleExportFullYear");
    console.log("Selected Year:", selectedYear);
    console.log("approvedTimesData:", approvedTimesData);
    
    try {
      let filteredTimes = approvedTimesData.filter(time => {
        if (!time || !time.start) return false;
        
        const datePart = time.start.split(' ')[0];
        const date = new Date(datePart);
        const year = date.getFullYear();
        
        return year === selectedYear;
      });
      
      console.log("Filtered times:", filteredTimes);
      
      if (selectedEmployee) {
        let employeeTransactions = filteredTimes.filter(time => time.employee === selectedEmployee);
        const employee = employeeList.find(e => e.employeeId === selectedEmployee);
        const firstName = employee ? employee.firstName : "";
        const lastName = employee ? employee.lastName : "";
        
        let worksheetData = [];
        
        worksheetData.push([
          "Datum",
          "Start",
          "Ende",
          "Pause",
          "Dauer (Stunden)",
          "Typ",
          "Status"
        ]);
        
        employeeTransactions.forEach(time => {
          if (!time.start) return;
          
          const startDateTime = new Date(time.start);
          const endDateTime = new Date(time.end || time.start);
          
          const date = startDateTime.toLocaleDateString('de-DE');
          
          const startTime = startDateTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
          const endTime = endDateTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
          
          let durationMinutes = (endDateTime - startDateTime) / 60000;
          let pauseMinutes = 0;
          
          if (time.pause) {
            const pauseParts = time.pause.split(':').map(Number);
            const pauseHours = pauseParts[0];
            pauseMinutes = (pauseHours * 60) + pauseParts[1];
            durationMinutes -= pauseMinutes;
          }
          
          if (time.type) {
            const timeClass = timeClasses.find(tc => tc.name === time.type);
            if (timeClass && timeClass.factor !== undefined) {
              const factor = parseFloat(timeClass.factor);
              durationMinutes *= factor;
            }
          }
          
          const durationHours = durationMinutes / 60;
          
          let status = time.managementType || "-";
          if (status === "pending") status = "Ausstehend";
          else if (status === "denied") status = "Abgelehnt";
          else if (status === "approved") status = "Bestätigt";
          
          worksheetData.push([
            date,
            startTime,
            endTime,
            time.pause || "-",
            durationHours.toFixed(2),
            time.type || "-",
            status
          ]);
        });
        
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, `${firstName}_${lastName}_Year`);
        XLSX.writeFile(workbook, `${firstName}_${lastName}_Year_${selectedYear}_Export.xlsx`);
        
        return;
      }
      
      const timesByEmployee = {};
      filteredTimes.forEach(time => {
        if (!timesByEmployee[time.employee]) {
          timesByEmployee[time.employee] = [];
        }
        timesByEmployee[time.employee].push(time);
      });
      
      console.log("Times by employee:", timesByEmployee);
      
      let worksheetData = [];
      
      worksheetData.push([
        "Vorname", 
        "Nachname", 
        "Personalnummer", 
        "Soll Stunden", 
        "Ist Stunden", 
        "Vorläufige Jahresprognose", 
        "Gesamt AZK", 
        "Gesamt Krank", 
        "Jahresanspruch Urlaub", 
        "Gewährter Urlaub", 
        "Resturlaub"
      ]);
      
      Object.entries(timesByEmployee).forEach(([employeeId, times]) => {
        const employee = employeeList.find(e => e.employeeId === employeeId);
        const firstName = employee ? employee.firstName : "";
        const lastName = employee ? employee.lastName : "";
        
        const jahresplanTimes = times.filter(time => time.type === "Jahresplan");
        let sollStundenMinutes = 0;
        jahresplanTimes.forEach(time => {
          const startDate = new Date(time.start);
          const endDate = new Date(time.end || time.start);
          const durationMs = endDate - startDate;
          const durationMinutes = durationMs / 60000;
          sollStundenMinutes += durationMinutes;
        });
        
        const workTimes = times.filter(time => time.type !== "Jahresplan");
        let istStundenMinutes = 0;
        let specialFactorMinutes = 0;
        
        workTimes.forEach(time => {
          if ((time.managementType === 'approved' || time.managementType === 'bestätigt' || 
               time.type === 'approved' || time.type === 'bestätigt')) {
            const startDate = new Date(time.start);
            const endDate = new Date(time.end || time.start);
            let durationMinutes = (endDate - startDate) / 60000;
            
            if (time.type) {
              const timeClass = timeClasses.find(tc => tc.name === time.type);
              if (timeClass && timeClass.specialFactor === true) {
                specialFactorMinutes += durationMinutes;
                return;
              }
            }
            
            if (time.pause) {
              const pauseParts = time.pause.split(':').map(Number);
              const pauseHours = pauseParts[0];
              const pauseInMinutes = (pauseHours * 60) + pauseParts[1];
              durationMinutes -= pauseInMinutes;
            }
            
            if (time.type) {
              const timeClass = timeClasses.find(tc => tc.name === time.type);
              if (timeClass && timeClass.factor !== undefined) {
                const factor = parseFloat(timeClass.factor);
                console.log(`Applying factor ${factor} to time entry of type ${time.type}`);
                durationMinutes *= factor;
              }
            }
            
            istStundenMinutes += durationMinutes;
          }
        });
        
        const sollStunden = sollStundenMinutes / 60;
        const istStunden = istStundenMinutes / 60;
        const specialFactorHours = specialFactorMinutes / 60;
        
        const adjustedSollStunden = sollStunden - specialFactorHours;
        
        const jahresprognose = istStunden - adjustedSollStunden;
        
        const azkTimes = times.filter(time => time.type === "AZK");
        const gesamtAZK = azkTimes.length;
        
        const urlaubTimes = times.filter(time => time.type === "Urlaub");
        const gewaerterUrlaub = urlaubTimes.length;
        
        const krankTimes = times.filter(time => time.type === "Krank");
        const gesamtKrank = krankTimes.length;
        
        const jahresanspruchUrlaub = employee?.holidayValue || 0;
        
        const resturlaub = jahresanspruchUrlaub - gewaerterUrlaub;
        
        worksheetData.push([
          firstName,
          lastName,
          employeeId,
          adjustedSollStunden.toFixed(2),
          istStunden.toFixed(2),
          jahresprognose.toFixed(2),
          gesamtAZK,
          gesamtKrank,
          jahresanspruchUrlaub,
          gewaerterUrlaub,
          resturlaub
        ]);
      });
      
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `Year_${selectedYear}`);
      XLSX.writeFile(workbook, `Year_${selectedYear}_Export.xlsx`);
      
    } catch (error) {
      console.error("Error in handleExportFullYear:", error);
    }
  };

  const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2024 + 1 }, (_, i) => currentYear - i).sort((a, b) => b - a);

  return (
    <div className="px-[83px] py-[48px] relative">
      {loading && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 10,
        }}>
          <Oval
            height={80}
            width={80}
            color="#0000FF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#0000FF"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      
          <h1 className="text-[24px] leading-[32px] font-[700]">Zeitexport</h1>
          <p className='text-[12px] leading-[18px] font-[500] mb-[34px]'>Erfasste Mitarbeiterzeiten exportieren</p>
          <p className='text-[12px] leading-[18px] font-[500]'>Jahr auswählen</p>
          <div className="flex items-center mb-[16px] space-x-4 h-[40px]">
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(Number(e.target.value))}
              className="border rounded py-2 px-3 w-[60%] h-[40px]"
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
        <button className="bg-[#E8EDFF] text-[#0000FF] py-2 px-4 rounded-[8px] w-[20%] h-[40px] flex items-center justify-center" onClick={handleExportSelectedMonths} disabled={loading}>
              {selectedMonths.length} Dateien
              <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
            </button>
            <button className={`bg-[#0000FF] text-white py-2 px-4 rounded-[8px] ${loading ? 'cursor-wait' : 'cursor-pointer'} w-[20%] h-[40px] flex items-center justify-center`} onClick={handleExportFullYear} disabled={loading}>
              Export Jahr {selectedYear}
              <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
            </button>
          </div>
          
          <div className="flex items-center mb-[16px] space-x-4 h-[40px]">
            <select
              value={selectedEmployee}
              onChange={(e) => {
                setSelectedEmployee(e.target.value);
                setSelectedMonths([]);
              }}
              className="border rounded py-2 px-3 w-[58.25%] h-[40px]"
            >
              <option value="">Nach Mitarbeiter filtern</option>
              {employeeList.map((employee) => (
                <option key={employee._id} value={employee._id}>
                  {employee.displayName}
                </option>
              ))}
            </select>
          </div>

          <div>
            {uniqueMonths.length > 0 ? (
              uniqueMonths
                .map(month => (
                  <div key={month} className="flex items-center justify-between h-[80px] p-4 border-b bg-[#FCFCFC] mb-[8px] rounded-[3px] shadow-sm">
                    <div className="flex items-center space-x-4">
                      <input
                        type="checkbox"
                        checked={selectedMonths.includes(month)}
                        onChange={() => handleMonthSelection(month)}
                        disabled={loading}
                      />
                      <span className="text-[16px] leading-[18px] font-[700]">
                        {monthNames[month]} {selectedYear}
                      </span>
                    </div>
                    <a 
                      href="#" 
                      className={`text-[#0000FF] ${loading ? 'pointer-events-none opacity-50' : ''}`} 
                      onClick={(e) => {
                        if (loading) {
                          e.preventDefault();
                          return;
                        }
                        handleExportMonth(month);
                      }}
                    >
                      Download {monthNames[month]}
                    </a>
                  </div>
                ))
            ) : (
              <p>Keine Monate gefunden</p>
            )}
          </div>
    </div>
  );
};

export default Export;