import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import * as Realm from 'realm-web';
import { saveAs } from 'file-saver';
import { DocumentIcon, ArrowDownTrayIcon, PlusIcon } from '@heroicons/react/24/solid';

const Settings = ({ setLoggedIn }) => {
  const [employee, setEmployee] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [holidayData, setHolidayData] = useState(null);
  const [inputError, setInputError] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState('');
  const [uploadStatus, setUploadStatus] = useState(null);
  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const app = new Realm.App({ id: process.env.REACT_APP_MONGO_APP });
  const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    validateToken(token)
      .then(async isValid => {
        if (!isValid) {
          if (stayLoggedIn) {
            const refreshed = await refreshAccessToken();
            if (!refreshed) {
              setLoggedIn(false);
              navigate('/login');
            } else {
              const newToken = app.currentUser.accessToken;
              const isValidNewToken = await validateToken(newToken);
              if (!isValidNewToken) {
                setLoggedIn(false);
                navigate('/login');
              } else {
                setLoggedIn(true);
              }
            }
          } else {
            setLoggedIn(false);
            navigate('/login');
          }
        } else {
          setLoggedIn(true);
        }
      })
      .catch(error => {
        setLoggedIn(false);
        navigate('/login');
      });
  }, [token, setLoggedIn, navigate, stayLoggedIn]);

  const validateToken = (token) => {
    return new Promise((resolve, reject) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({ token: token }),
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.error) {
              resolve(false);
            } else {
              setEmployee(result);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  const refreshAccessToken = async () => {
    try {
      await app.currentUser.refreshAccessToken();
      const newAccessToken = app.currentUser.accessToken;
      localStorage.setItem('accessToken', newAccessToken);
      return true;
    } catch (error) {
      return false;
    }
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = "Beispiel 100% Vertrag";
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      setFileData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  const onHolidayDrop = (acceptedFiles) => {
    console.log("File dropped:", acceptedFiles);
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      console.log("File loaded successfully");
      let workbook = null;
      try {
        workbook = XLSX.read(event.target.result, {
          type: 'array',
        });
        console.log("Workbook loaded. Available sheets:", workbook.SheetNames);
      } catch (error) {
        console.error("Unsupported file format", error);
        return;
      }
  
      const sheet_name = "Beispiel 100% Vertrag";
      console.log("Using sheet:", sheet_name);
      const sheet = workbook.Sheets[sheet_name];
      if (!sheet) {
        console.error("Sheet not found");
        return;
      }
      
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const dateRow = jsonData[3]; 
      const holidayRow = jsonData[18];
      
      if (!holidayRow || !holidayRow[0] || !holidayRow[2] || typeof holidayRow[2] !== 'number') {
        console.error("Invalid vacation plan row format");
        return;
      }

      const employeeId = holidayRow[2];
      const employeeName = holidayRow[0];
      const holidays = { [employeeId]: [] };
      
      console.log(`Processing vacation plan for employee: ${employeeName}, ID: ${employeeId}`);

      const absenceTypeMapping = {
        '1': 'Urlaub',
        'U': 'Urlaub',
        'UoB': 'Urlaub ohne Beleg',
        'GZoB': 'Gleittag ohne Beleg',
        'AZKoB': 'AZK ohne Beleg',
        'AUoB': 'Krank ohne Beleg',
        'SU': 'Sonderurlaub',
        'BU': 'Bildungsurlaub',
        'GZ': 'Gleittag',
        'AZK': 'AZK',
        'AU': 'Krank',
        'KFF': 'Krank Ferienfrei',
        'Kk': 'Kind krank',
        'KG': 'Krank mit Krankengeld',
        'KvertAZ': 'Krank vertragliche Arbeitszeit'
      };

      const validAbsenceTypes = Object.keys(absenceTypeMapping);

      for (let j = 0; j < holidayRow.length; j++) {
        const cellValue = holidayRow[j];
        if (cellValue === 1 || (typeof cellValue === 'string' && validAbsenceTypes.includes(cellValue))) {
          const dateValue = dateRow[j];
          
          if (dateValue && dateValue instanceof Date) {
            const day = dateValue.getDate();
            const month = dateValue.getMonth() + 1;
            
            holidays[employeeId].push({
              date: `${day}.${month}`,
              status: absenceTypeMapping[cellValue === 1 ? '1' : cellValue],
            });
          }
        }
      }
      
      console.log(`Found ${holidays[employeeId].length} vacation days for employee ${employeeId}`);
      console.log("Final holiday data:", holidays);
      
      const token = localStorage.getItem('accessToken');
      const requestBody = JSON.stringify({
        token,
        holidayPlans: holidays,
      });
      
      const requestOptions = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: requestBody,
      };

      fetch("https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addHolidayVersion2", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            console.log("Holiday data uploaded successfully to version 2:", result);
          } else {
            console.error("Error uploading holiday data to version 2:", result);
          }
        })
        .catch(error => {
          console.error("Error uploading holiday data to version 2:", error);
        });

      fetch("https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addHolidayPlan", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            console.log("Holiday data uploaded successfully to original endpoint:", result);
          } else {
            console.error("Error uploading holiday data to original endpoint:", result);
          }
        })
        .catch(error => {
          console.error("Error uploading holiday data to original endpoint:", error);
        });
    };
  
    reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const { getRootProps: getHolidayRootProps, getInputProps: getHolidayInputProps } = useDropzone({ onDrop: onHolidayDrop });

  useEffect(() => {
    if (fileData) {
      addAnnualPlans();
    }
  }, [fileData]);

  useEffect(() => {
    localStorage.setItem('company', employee?.company)
  }, [employee]);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
      navigate('/login');
      return;
    }

    validateToken(token)
      .then(isValid => {
        if (!isValid) {
          setLoggedIn(false)
          navigate('/login');
        }
      })
      .catch(error => {
        setLoggedIn(false)
        navigate('/login');
      });
  }, [token, setLoggedIn, navigate]);

  const addAnnualPlans = async () => {
    console.log("Processing annual plans from row 8");
    
    const dateRow = fileData[3];
    const annualPlanRow = fileData[7];
    
    if (!annualPlanRow || !annualPlanRow[0] || !annualPlanRow[2] || typeof annualPlanRow[2] !== 'number') {
      console.error("Invalid annual plan row format");
      return;
    }

    const employeeId = annualPlanRow[2];
    const employeeName = annualPlanRow[0];
    const annualPlans = { [employeeId]: [] };
    
    console.log(`Processing annual plan for employee: ${employeeName}, ID: ${employeeId}`);

    for (let j = 8; j <= 376; j++) {
      const hours = annualPlanRow[j];
      if (typeof hours === 'number') {
        const dateValue = dateRow[j];
        
        if (dateValue && dateValue instanceof Date) {
          const day = dateValue.getDate();
          const month = dateValue.getMonth() + 1;
          
          console.log(`Found working hours for ${day}.${month}: ${hours} hours`);
          
          annualPlans[employeeId].push({
            date: `${day}.${month}`,
            hours: hours
          });
        }
      }
    }
    
    console.log(`Found ${annualPlans[employeeId].length} working day entries for employee ${employeeId}`);
    console.log("Final annual plans data:", annualPlans);
    
    const url = 'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addAnnualPlanVersion2';
    if (annualPlans) {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          annualPlans: annualPlans,
          token: token
        })
      })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            setUploadStatus('success');
          } else {
            setUploadStatus('error');
          }
          console.log('Add Annual Plans result:', result);
        })
        .catch(error => {
          console.error('Error adding annual plans:', error);
        });
    }
  };

  const getEmployees = (rows) => {
    let res = {};

    for (const [, value] of Object.entries(rows)) {
      if (value[2].value !== undefined) {
        let date = new Date(`${new Date().getFullYear()}-01-01`);
        res[value[2].value] = value.slice(6)
          .map((obj) => {
            let newDate = new Date(date);
            date.setDate(date.getDate() + 1);
            return { ...obj, date: newDate };
          })
          .filter(timeslot => timeslot.status !== null);
      }
    }

    return res;
  };

  const downloadAnnualPlan = async () => {
    const url = 'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/downloadAnnualPlan';
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({ token }),
    };
  
    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();
      console.log(result);
  
      const modifiedData = result.map(({ _id, employeeId, monday, tuesday, wednesday, thursday, friday, name }) => ({
        Personalnummer: employeeId,
        Name: name,
        Montag: monday,
        Dienstag: tuesday,
        Mittwoch: wednesday,
        Donnerstag: thursday,
        Freitag: friday,
      }));
  
      const worksheet = XLSX.utils.json_to_sheet(modifiedData);
  
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'AnnualPlan');
  
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, 'Jahresplan.xlsx');
    } catch (error) {
      console.error('Error downloading annual plan:', error);
    }
  };

  const handleCloseDialog = () => {
    setUploadStatus(null);
  };

  return (
    <div className="py-[32px] px-[90px]">
      <h1 className="text-2xl font-bold">Einstellungen</h1>
      <p className="mb-[46px] text-gray-700">
        {employee?.isAdmin ? 
          "Hier kannst du deine Accounteinstellungen ändern und deinen Jahresplan verwalten." : 
          "Hier kannst du deine Accountinformationen einsehen."
        }
      </p>

      {employee?.isAdmin && (
        <>
          <div className="flex mb-[46px]">
            <div className="bg-white shadow-md rounded-xl w-full p-[16px] flex">
              <DocumentIcon className="h-5 w-5 mr-2 text-[#A7B3C4] self-start mt-1" />
              <div>
                <h2 className="text-xl font-bold text-[#A7B3C4]">Jahresplan</h2>
                <p className="text-sm text-[#A7B3C4] mb-4">Letzte Aktualisierung: 23.05.2023</p>
                <div className='border-t' />
                <div className="flex mt-[16px]">
                  {/* <button onClick={downloadAnnualPlan} className="bg-[#E8EDFF] text-[#0000FF] py-2 px-4 rounded-lg flex items-center mr-[16px]">
                    Download Jahresplan
                    <ArrowDownTrayIcon className="h-5 w-5 ml-2" />
                  </button> */}
                  <button {...getRootProps()} className="text-[#0000FF] py-2 px-4 rounded-lg flex items-center mr-2">
                    <input {...getInputProps()} />
                    <PlusIcon className="h-5 w-5 mr-2" />
                    Neuen Jahresplan anlegen
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex mb-[46px]">
            <div className="bg-white shadow-md rounded-xl w-full p-[16px] flex">
              <DocumentIcon className="h-5 w-5 mr-2 text-[#A7B3C4] self-start mt-1" />
              <div>
                <h2 className="text-xl font-bold text-[#A7B3C4]">Urlaubsplan</h2>
                <p className="text-sm text-[#A7B3C4] mb-4">Letzte Aktualisierung: 23.05.2023</p>
                <div className='border-t' />
                <div className="flex mt-[16px]">
                  <button {...getHolidayRootProps()} className="text-[#0000FF] py-2 px-4 rounded-lg flex items-center mr-2">
                    <input {...getHolidayInputProps()} />
                    <PlusIcon className="h-5 w-5 mr-2" />
                    Neuen Urlaubsplan anlegen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        <h2 className="text-xl font-bold mb-4 text-black">Sicherheit</h2>
        <div className='mb-[16px]'>
          <h2 className="text-[20px] font-bold text-[#101828] mb-4">Persönliche Informationen</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className='border-b pb-[16px]'>
              <label className="block text-[#101828] mb-1">Benutzername</label>
              <p className='font-[500]'>{employee?.email}</p>
            </div>
            <div className='border-b pb-[16px]'>
              <label className="block text-[#101828] mb-1">Passwort</label>
              <p className='font-[500]'>**********</p>
            </div>
          </div>
        </div>
        <div className="flex mb-[46px]">
          <button
            className="bg-[#E8EDFF] text-[#0000FF] py-2 px-4 rounded-lg flex items-center mr-[16px]"
            onClick={async () => {
              await app.emailPasswordAuth.sendResetPasswordEmail({ email: employee.email })
            }}
          >
            Passwort Zurücksetzen Link an E-Mail anfordern
          </button>
        </div>
      </div>
      <div>
        <h2 className="text-[20px] font-bold text-[#101828] mb-4">Persönliche Informationen</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Vorname</label>
            <p className='font-[500]'>{employee?.firstName}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Nachname</label>
            <p className='font-[500]'>{employee?.lastName}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Jobtitel</label>
            <p className='font-[500]'>{employee?.jobTitle}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Mitarbeiter-ID</label>
            <p className='font-[500]'>{employee?.employeeId}</p>
          </div>
          {/* <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Arbeitszeitmodell</label>
            <p className='font-[500]'>{employee?.timeModel}</p>
          </div> */}
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Wochenarbeitsstunden</label>
            <p className='font-[500]'>{employee?.hours_week}</p>
          </div>
          <div className='border-b pb-[16px]'>
            <label className="block text-[#101828] mb-1">Monatsarbeitsstunden</label>
            <p className='font-[500]'>{employee?.hours_month}</p>
          </div>
        </div>
      </div>

      {uploadStatus && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-10 rounded-lg shadow-lg w-3/4 max-w-3xl h-auto text-center">
            {uploadStatus === 'success' ? (
              <>
                <img src="/login-success.png" alt="Success" className="mx-auto mb-4 w-20 h-20" />
                <h2 className="text-2xl font-bold text-black mb-4">Upload erfolgreich!</h2>
              </>
            ) : (
              <h2 className="text-2xl font-bold text-black mb-4">Upload fehlgeschlagen</h2>
            )}
            <button onClick={handleCloseDialog} className="bg-[#0000FF] text-white py-2 px-6 rounded-md hover:bg-blue-700">Schließen</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Settings;