import React, { useState } from 'react';

const WarningDialog = ({ isOpen, onClose, onSave }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [isBetrieblichChecked, setIsBetrieblichChecked] = useState(false);

  const handleSave = () => {
    onSave(isChecked, selectedReason, isBetrieblichChecked);
    onClose();
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" 
      style={{ zIndex: 9999 }}
      onClick={handleOverlayClick}
    >
      <div className="bg-white rounded-lg shadow-lg p-6 w-96" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-red-500 text-xl font-bold">Warnung</h2>
        <p className="text-gray-600 mt-2">Die angegebenen Arbeitszeiten entsprechen nicht dem Dienstplan.</p>
        {/* <div className="mt-4">
          <label className="flex items-center space-x-3 mb-4">
            <input 
              type="checkbox" 
              className="form-checkbox" 
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)} 
            />
            <span>Keine Angabe machen</span>
          </label>
          <label className="block mb-2">Grund für Abweichung angeben</label>
          <select 
            className="w-full p-2 border border-gray-300 rounded"
            value={selectedReason}
            onChange={(e) => setSelectedReason(e.target.value)}
          >
            <option value="">Auswählen</option>
            <option value="Krank">Krank</option>
            <option value="Kind Krank">Kind Krank</option>
            <option value="Angeordet">Angeordet</option>
            <option value="Urlaub">Urlaub</option>
            <option value="Zeitausgleich">Zeitausgleich</option>
            <option value="Feiertag">Feiertag</option>
          </select>
          {selectedReason === 'Zeitausgleich' && (
            <label className="flex items-center space-x-3 mt-4">
              <input 
                type="checkbox" 
                className="form-checkbox" 
                checked={isBetrieblichChecked}
                onChange={(e) => setIsBetrieblichChecked(e.target.checked)} 
              />
              <span>Betrieblich angeordnet</span>
            </label>
          )}
        </div> */}
        <div className="flex justify-between mt-4">
          <button 
            className={`py-2 px-4 rounded-lg bg-blue-500 text-white`} 
            onClick={handleSave}
          >
            Bestätigen
          </button>
          <button className="text-blue-500" onClick={onClose}>Abbrechen</button>
        </div>
      </div>
    </div>
  );
};

export default WarningDialog;