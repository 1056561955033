import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles/Calendar.css';

const AbsenceDialog = ({ isOpen, onClose, onSave, isAdmin, isSuperAdmin, allTimeEntries = [], contractualAverageWorkingTime = 0 }) => {
  const [absenceData, setAbsenceData] = useState({
    selectedDates: [],
    selectedTimeClass: null
  });
  const [timeClasses, setTimeClasses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setAbsenceData({
        selectedDates: [],
        selectedTimeClass: null
      });
      fetchTimeClasses();
    }
  }, [isOpen]);

  const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const fetchTimeClasses = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(
        'https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimeClasses',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        }
      );
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching time classes:', result.error);
      } else {
        setTimeClasses(result);
      }
    } catch (error) {
      console.error('Error fetching time classes:', error);
    } finally {
      setLoading(false);
    }
  };

  const isSaveDisabled = absenceData.selectedDates.length === 0 || 
                         !absenceData.selectedTimeClass;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'timeClass') {
      const selectedTimeClass = timeClasses.find(tc => tc.name === value);
      setAbsenceData({
        ...absenceData,
        selectedTimeClass: selectedTimeClass || null
      });
    } else {
      setAbsenceData({
        ...absenceData,
        [name]: value
      });
    }
  };

  const handleDateChange = (value) => {
    if (!Array.isArray(value)) {
      setAbsenceData(prev => ({ ...prev, selectedDates: [] }));
      return;
    }

    const [start, end] = value;
    console.log('Raw selected dates from calendar:', {
      start: start.toLocaleDateString('de-DE'),
      end: end.toLocaleDateString('de-DE')
    });

    const dates = [];
    const currentDate = new Date(start);
    const endDate = new Date(end);

    currentDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    console.log('After new Date():', {
      currentDate: currentDate.toLocaleDateString('de-DE'),
      endDate: endDate.toLocaleDateString('de-DE')
    });

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    console.log('Generated dates array:', dates.map(d => d.toLocaleDateString('de-DE')));

    setAbsenceData(prev => ({
      ...prev,
      selectedDates: dates
    }));
  };

  const handleSave = () => {
    console.log('Dates before formatting:', absenceData.selectedDates.map(d => d.toLocaleDateString('de-DE')));
    
    const formattedDates = absenceData.selectedDates.map(date => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    });
    
    const startTime = "00:01";
    
    const hours = Math.floor(contractualAverageWorkingTime);
    const minutes = Math.round((contractualAverageWorkingTime - hours) * 60);
    
    const endHours = hours;
    const endMinutes = minutes + 1;
    
    const finalEndHours = endHours + Math.floor(endMinutes / 60);
    const finalEndMinutes = endMinutes % 60;
    
    const endTime = `${String(finalEndHours).padStart(2, '0')}:${String(finalEndMinutes).padStart(2, '0')}`;
    
    console.log(`Using contractualAverageWorkingTime: ${contractualAverageWorkingTime} hours`);
    console.log(`Setting absence times - Start: ${startTime}, End: ${endTime}`);
    
    for (const date of formattedDates) {
      const dataToSend = {
        startTime: startTime,
        endTime: endTime,
        reason: absenceData.selectedTimeClass.name,
        type: absenceData.selectedTimeClass.name,
        selectedDates: [date]
      };
      
      if (absenceData.selectedTimeClass.factor) {
        dataToSend.factor = absenceData.selectedTimeClass.factor;
      }
      
      console.log('Sending data for date', date, dataToSend);
      onSave(dataToSend);
    }
    
    onClose();
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const getFilteredTimeClasses = () => {
    if (loading) {
      return [];
    }
    
    return timeClasses.filter(timeClass => {
      if (!timeClass.hasOwnProperty('permission')) return false;
      
      if (timeClass.permission === 'admin' && (isAdmin || isSuperAdmin)) return true;
      if (timeClass.permission === 'superAdmin' && isSuperAdmin) return true;
      return false;
    });
  };

  if (!isOpen) return null;

  const filteredTimeClasses = getFilteredTimeClasses();

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center" 
      style={{ zIndex: 9999 }}
      onClick={handleOverlayClick}
    >
      <div 
        className="bg-gray-100 rounded-lg shadow-lg w-[90%] max-w-[800px] p-6 m-0"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-bold mb-4">Abwesenheit hinzufügen</h2>
        <p className="text-sm text-gray-600 mb-4">Eine angelegte Abwesenheit bedeutet, dass dem Mitarbeiter für diesen Zeitraum Arbeitsstunden angerechnet werden.</p>
        
        <div className="flex flex-col md:flex-row gap-6">
          <div className="w-full md:w-1/2">
            
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Grund für Abwesenheit</label>
              <select
                name="timeClass"
                value={absenceData.selectedTimeClass?.name || ''}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              >
                <option value="">-- Bitte auswählen --</option>
                {filteredTimeClasses.map((timeClass) => (
                  <option key={timeClass._id} value={timeClass.name}>
                    {timeClass.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="w-full md:w-1/2">
            <label className="block text-gray-700 mb-2">Tage auswählen</label>
            <div className="calendar-container bg-gray-50 rounded border border-gray-300">
              <Calendar
                onChange={handleDateChange}
                value={absenceData.selectedDates.length > 0 ? [
                  absenceData.selectedDates[0],
                  absenceData.selectedDates[absenceData.selectedDates.length - 1]
                ] : null}
                selectRange={true}
                className="w-full border-0 p-2 bg-gray-50"
              />
            </div>
            {absenceData.selectedDates.length > 0 && (
              <div className="mt-2 text-sm text-gray-600">
                <p>Ausgewählte Tage: {absenceData.selectedDates.length}</p>
                <p className="mt-1">
                  {absenceData.selectedDates.length === 1 
                    ? absenceData.selectedDates[0].toLocaleDateString('de-DE')
                    : `${absenceData.selectedDates[0].toLocaleDateString('de-DE')} - ${absenceData.selectedDates[absenceData.selectedDates.length - 1].toLocaleDateString('de-DE')}`
                  }
                </p>
              </div>
            )}
          </div>
        </div>
        
        <div className="flex justify-end mt-4">
          <button 
            className={`bg-[#0000FF] text-white py-2 px-4 rounded-lg mr-2 ${isSaveDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            Hinzufügen
          </button>
          <button 
            className="text-[#0000FF] py-2 px-4 rounded-lg"
            onClick={onClose}
          >
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  );
};

export default AbsenceDialog;
