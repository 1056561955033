import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Realm from 'realm-web';
import '../App.css';
// import { ReactComponent as HidePassword } from '../images/hide-password-icon.svg';
// import { ReactComponent as ShowPassword } from '../images/show-password-icon.svg';
import { Oval } from 'react-loader-spinner';

function Login({ setLoggedIn }) {
  const app = new Realm.App({ id: process.env.REACT_APP_MONGO_APP });

  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(localStorage.getItem('stayLoggedIn') === 'true');
  const [loginFailed, setLoginFailed] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [timeModel, setTimeModel] = useState('Vollzeit');
  const [hoursWeek, setHoursWeek] = useState('');
  const [hoursMonth, setHoursMonth] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [legalForm, setLegalForm] = useState('GmbH');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [faxNumber, setFaxNumber] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [ceo, setCeo] = useState('');
  const [state, setState] = useState('');
  const [website, setWebsite] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [taxNumber, setTaxNumber] = useState('');
  const [invalidFields, setInvalidFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    validateToken(token)
      .then((isValid) => {
        if (isValid) {
          const isBRAdmin = localStorage.getItem('isBRAdmin') === 'true';
          navigate(isBRAdmin ? '/time-management' : '/home');
        }
      })
      .catch((error) => {
        console.error('Error validating token:', error);
      });
  }, [token, navigate]);

  const validateToken = (token) => {
    return new Promise((resolve, reject) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({
            token: token,
          }),
          redirect: 'follow',
        };

        fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.error) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const processedEmail = email.includes('@') ? email.toLowerCase() : `${email.toLowerCase()}@time-snap.de`;
  
    try {
      const user = await loginEmailPassword(processedEmail, password);
      localStorage.setItem('accessToken', user._accessToken);
  
      const employeeResponse = await fetch("https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getEmployee", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: user._accessToken,
        }),
      });
  
      const employeeResponseData = await employeeResponse.json();

      console.log(employeeResponseData)
  
      localStorage.removeItem('admin');
      localStorage.removeItem('isBRAdmin');
      localStorage.setItem('admin', employeeResponseData?.isAdmin);
      localStorage.setItem('isBRAdmin', employeeResponseData?.isBRAdmin);
  
      await fetch("https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/saveToken", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          employeeId: employeeResponseData.employeeId,
          token: user._accessToken,
          refreshToken: user._refreshToken,
        }),
      });
  
      setLoggedIn(true);

      if (employeeResponseData?.isBRAdmin) {
        navigate('/time-management');
      } else {
        navigate('/home');
      }

    } catch (e) {
      console.log(e);
      setLoginFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const loginEmailPassword = async (email, password) => {
    const processedEmail = email.includes('@') ? email.toLowerCase() : `${email.toLowerCase()}@time-snap.de`;
    const credentials = Realm.Credentials.emailPassword(processedEmail, password);
    console.log(credentials);
    const user = await app.logIn(credentials);
    return user;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
  
    const processedEmail = email.includes('@') ? email.toLowerCase() : `${email.toLowerCase()}@time-snap.de`;
  
    try {
      await app.emailPasswordAuth.registerUser({ email: processedEmail, password });
      console.log('User registered successfully');
  
      const companyData = {
        companyName,
        industry,
        legalForm,
        phoneNumber,
        faxNumber,
        companyEmail,
        streetAddress,
        ceo,
        state,
        website,
        vatNumber,
        taxNumber,
      };
  
      console.log('Company data:', companyData);
  
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          email: processedEmail,
          password,
          companyData,
          firstName,
          lastName,
          jobTitle,
          employeeId,
          timeModel,
          hours_week: hoursWeek,
          hours_month: hoursMonth
        }),
        redirect: 'follow',
      };
  
      fetch(process.env.REACT_APP_REGISTER_URL, requestOptions)
        .then((response) => response.json())
        .then(async (result) => {
          console.log(result);
          setRegisterSuccess(true);
        })
        .catch((error) => console.log('error', error));
    } catch (error) {
      console.error('Error registering user:', error);
    }
  };

  const handleForgotPassword = async () => {
    await app.emailPasswordAuth.sendResetPasswordEmail({ email });
  };

  const togglePasswordVisible = (visible) => {
    setPasswordVisible(visible);
  };

  const nextStep = () => {
    if (validateStep()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderDots = () => {
    return (
      <div className="flex justify-center mt-4">
        {[1, 2, 3, 4, 5, 6].map((step) => (
          <div
            key={step}
            className={`w-2 h-2 mx-1 rounded-full ${
              currentStep === step ? 'bg-primary' : 'bg-gray-300'
            }`}
          ></div>
        ))}
      </div>
    );
  };

  const validateStep = () => {
    let invalids = [];
    switch (currentStep) {
      case 1:
        if (!email) invalids.push('E-Mail');
        if (!password) invalids.push('Passwort');
        if (!firstName) invalids.push('Vorname');
        if (!lastName) invalids.push('Nachname');
        if (!jobTitle) invalids.push('Job Titel');
        if (!employeeId) invalids.push('Mitarbeiter ID');
        // if (!timeModel) invalids.push('Arbeitszeitmodell');
        if (!hoursWeek) invalids.push('Stunden per Woche');
        if (!hoursMonth) invalids.push('Stunden per Monat');
        break;
      case 2:
        if (!companyName) invalids.push('Firmenname');
        if (!industry) invalids.push('Industrie');
        if (!legalForm) invalids.push('Rechtsform');
        break;
      case 3:
        if (!streetAddress) invalids.push('Straße und Hausnummer');
        if (!postalCode) invalids.push('Postleitzahl');
        if (!country) invalids.push('Land Firmensitz');
        break;
      case 4:
        if (!ceo) invalids.push('Geschäftsführer');
        if (!state) invalids.push('Zuständiges Bundesland');
        break;
      case 5:
        if (!phoneNumber) invalids.push('Telefonnummer Unternehmen');
        if (!companyEmail) invalids.push('Firmen-E-Mailadresse');
        break;
      case 6:
        if (!website) invalids.push('Firmen-Website');
        break;
      default:
        break;
    }
    setInvalidFields(invalids);
    return invalids.length === 0;
  };

  const handleRememberChange = (e) => {
    const isChecked = e.target.checked;
    setRemember(isChecked);
    localStorage.setItem('stayLoggedIn', isChecked);
  };

  const renderStepContent = () => {
    const fieldErrors = invalidFields.length > 0 && (
      <div className="text-sm text-red-600 mb-4">
        Bitte trage folgende Felder ein: {invalidFields.join(', ')}
      </div>
    );

    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-2">
            {fieldErrors}
            <div>
              <label htmlFor="email" className="text-gray-700">
                E-Mail
              </label>
              <input
                id="email"
                name="email"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('E-Mail') ? 'border-red-500' : ''
                }`}
                placeholder="E-Mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="passwort" className="text-gray-700">
                Passwort
              </label>
              <input
                id="password"
                name="password"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Passwort') ? 'border-red-500' : ''
                }`}
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="first-name" className="text-gray-700">
                Vorname
              </label>
              <input
                id="first-name"
                name="first-name"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Vorname') ? 'border-red-500' : ''
                }`}
                placeholder="Vorname"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="last-name" className="text-gray-700">
                Nachname
              </label>
              <input
                id="last-name"
                name="last-name"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Nachname') ? 'border-red-500' : ''
                }`}
                placeholder="Nachname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="employeeId" className="text-gray-700">
                Mitarbeiter ID
              </label>
              <input
                id="employeeId"
                name="employeeId"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Mitarbeiter ID')
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="Mitarbeiter ID"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="jobTitle" className="text-gray-700">
                Job Titel
              </label>
              <input
                id="jobTitle"
                name="jobTitle"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Job Titel') ? 'border-red-500' : ''
                }`}
                placeholder="Job Titel"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
              />
            </div>
            {/* <div>
              <label htmlFor="time-model" className="text-gray-700">
                Arbeitszeitmodell
              </label>
              <select
                id="time-model"
                name="time-model"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Arbeitszeitmodell')
                    ? 'border-red-500'
                    : ''
                }`}
                value={timeModel}
                onChange={(e) => setTimeModel(e.target.value)}
              >
                <option value="Vollzeit">Vollzeit</option>
                <option value="Teilzeit">Teilzeit</option>
              </select>
            </div> */}
            <div>
              <label htmlFor="hoursWeek" className="text-gray-700">
                Stunden per Woche
              </label>
              <input
                id="hoursWeek"
                name="hoursWeek"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Stunden per Woche')
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="Stunden per Woche"
                value={hoursWeek}
                onChange={(e) => setHoursWeek(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="hoursMonth" className="text-gray-700">
                Stunden per Monat
              </label>
              <input
                id="hoursMonth"
                name="hoursMonth"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Stunden per Monat')
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="Stunden per Monat"
                value={hoursMonth}
                onChange={(e) => setHoursMonth(e.target.value)}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-3">
            {fieldErrors}
            <div>
              <label htmlFor="company-name" className="text-gray-700">
                Firmenname
              </label>
              <input
                id="company-name"
                name="company-name"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Firmenname') ? 'border-red-500' : ''
                }`}
                placeholder="z.B. co-dex"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="industry" className="text-gray-700">
                Industrie
              </label>
              <input
                id="industry"
                name="industry"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Industrie') ? 'border-red-500' : ''
                }`}
                placeholder="z.B. Technologie"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="legal-form" className="text-gray-700">
                Rechtsform Unternehmen
              </label>
              <select
                id="legal-form"
                name="legal-form"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Rechtsform') ? 'border-red-500' : ''
                }`}
                value={legalForm}
                onChange={(e) => setLegalForm(e.target.value)}
              >
                <option value="GmbH">GmbH</option>
                <option value="Einzelunternehmen">Einzelunternehmen</option>
                <option value="AG">AG</option>
                <option value="GbR">GbR</option>
                <option value="OHG">OHG</option>
              </select>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-3">
            {fieldErrors}
            <div>
              <label htmlFor="street-address" className="text-gray-700">
                Straße und Hausnummer Firmensitz
              </label>
              <input
                id="street-address"
                name="street-address"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Straße und Hausnummer')
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="z.B. Erlenring 24"
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="postal-code" className="text-gray-700">
                Postleitzahl Firmensitz
              </label>
              <input
                id="postal-code"
                name="postal-code"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Postleitzahl') ? 'border-red-500' : ''
                }`}
                placeholder="z.B. 38448"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="country" className="text-gray-700">
                Land Firmensitz
              </label>
              <input
                id="country"
                name="country"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Land Firmensitz')
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="z.B. Deutschland"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-3">
            {fieldErrors}
            <div>
              <label htmlFor="ceo" className="text-gray-700">
                Geschäftsführer*in
              </label>
              <input
                id="ceo"
                name="ceo"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Geschäftsführer')
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="z.B. Frau Chefin"
                value={ceo}
                onChange={(e) => setCeo(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="state" className="text-gray-700">
                Zuständiges Bundesland
              </label>
              <input
                id="state"
                name="state"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Zuständiges Bundesland')
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="z.B. Niedersachsen"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
          </div>
        );
      case 5:
        return (
          <div className="space-y-3">
            {fieldErrors}
            <div>
              <label htmlFor="company-phone-number" className="text-gray-700">
                Telefonnummer Unternehmen
              </label>
              <input
                id="company-phone-number"
                name="company-phone-number"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Telefonnummer Unternehmen')
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="000 000000 00"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="company-fax" className="text-gray-700">
                Fax Unternehmen
              </label>
              <input
                id="company-fax"
                name="company-fax"
                type="text"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Fax Unternehmen')
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="000 000000 00"
                value={faxNumber}
                onChange={(e) => setFaxNumber(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="company-email" className="text-gray-700">
                Firmen-E-Mailadresse
              </label>
              <input
                id="company-email"
                name="company-email"
                type="email"
                required
                className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                  invalidFields.includes('Firmen-E-Mailadresse')
                    ? 'border-red-500'
                    : ''
                }`}
                placeholder="Beispiel@email.de"
                value={companyEmail}
                onChange={(e) => setCompanyEmail(e.target.value)}
              />
            </div>
          </div>
        );
        case 6:
          return (
            <div className="space-y-3">
              {fieldErrors}
              <div>
                <label htmlFor="website" className="text-gray-700">
                  Firmen-Website
                </label>
                <input
                  id="website"
                  name="website"
                  type="text"
                  required
                  className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                    invalidFields.includes('Firmen-Website')
                      ? 'border-red-500'
                      : ''
                  }`}
                  placeholder="Beispiel.de"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="vat-number" className="text-gray-700">
                  Ust-Nummer
                </label>
                <input
                  id="vat-number"
                  name="vat-number"
                  type="text"
                  className="appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                  placeholder="z.B. DE123456789"
                  value={vatNumber}
                  onChange={(e) => setVatNumber(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="tax-number" className="text-gray-700">
                  Steuernummer
                </label>
                <input
                  id="tax-number"
                  name="tax-number"
                  type="text"
                  required
                  className={`appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm ${
                    invalidFields.includes('Steuernummer') ? 'border-red-500' : ''
                  }`}
                  placeholder="z.B. 11/222/33333"
                  value={taxNumber}
                  onChange={(e) => setTaxNumber(e.target.value)}
                />
              </div>
            </div>
          );
        default:
          return null;
      }
    };
  
    return (
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        {loading ? (
          <Oval
            height={80}
            width={80}
            color="#0000FF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#0000FF"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
        <div className="max-w-md w-full space-y-8">
          {
            !registerSuccess &&
            <div>
              <div className="flex justify-center">
                <div className="bg-logo-bg size-[150px] content-center rounded-[20px]">
                  <img
                    className="mx-auto w-full"
                    src={
                      currentStep === 1 && !isRegister
                        ? "timesnap-logo.png"
                        : currentStep === 1 && isRegister
                        ? "user-login.png"
                        : "company-login.png"
                    }
                    alt={
                      currentStep === 1 && !isRegister
                        ? "Timesnap Logo"
                        : currentStep === 1 && isRegister
                        ? "User Logo"
                        : "Company Login"
                    }
                  />
                </div>
              </div>
              <h2 className="mt-6 text-center text-[26px] font-extrabold text-gray-900">
                {currentStep === 1 && !isRegister ? "Bei Wollino Timer einloggen" : currentStep === 1 && isRegister ? "Neuen Account anlegen" : "Unternehmen anlegen"}
              </h2>
            </div>
          }
          {!isRegister ? (
            <form className="mt-8 space-y-6" onSubmit={handleLogin}>
              <input type="hidden" name="remember" value="true" />
              <div className="rounded-md">
                <div>
                  <label
                    htmlFor="email-address"
                    className="text-[#424B58] text-sm font-semibold"
                  >
                    E-Mail
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    autoComplete="email"
                    required
                    className="appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                    placeholder="Beispiel@email.de"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="password"
                    className="text-[#424B58] text-sm font-semibold"
                  >
                    Passwort
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      name="password"
                      type={passwordVisible ? 'text' : 'password'}
                      autoComplete="current-password"
                      required
                      className="appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Passwort"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() => togglePasswordVisible(!passwordVisible)}
                    >
                      {/* {passwordVisible ? (
                        <HidePassword className="h-5 w-5 text-gray-400" />
                      ) : (
                        <ShowPassword className="h-5 w-5 text-gray-400" />
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
  
              {loginFailed && (
                <div className="text-sm text-red-600">
                  Email address and password do not match.
                </div>
              )}
  
              <div className="flex items-center justify-between mt-4">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    checked={remember}
                    onChange={handleRememberChange}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Einlogdaten speichern
                  </label>
                </div>
              </div>
  
              <div>
                <button
                  type="submit"
                  className="mt-10 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primaryDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Einloggen
                </button>
              </div>
              <div className="text-sm text-center">
                <span
                  onClick={() => setIsRegister(true)}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-primary bg-[#E8EDFF] hover:bg-primary/5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                >
                  Registrieren
                </span>
              </div>
            </form>
          ) : registerSuccess ? (
            <div className="text-center">
              <img src="login-success.png" alt="Illustration" className="mx-auto mb-[25px]" />
              <div className="font-bold text-[24px] leading-[32px]">
                Das hat funktioniert!
              </div>
              <div className="mb-[72px] text-[14px] font-[400] text-[#A7B3C4]">
                Unternehmen wurde angelegt. Die Anwendung kann jetzt gestartet
                werden.
              </div>
              <button
                type="button"
                onClick={() => {
                  setRegisterSuccess(false)
                  setIsRegister(false)
                  setCurrentStep(1)
                }}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-[#0000FF]"
              >
                Zum Login
              </button>
            </div>
          ) : (
            <form className="mt-8 space-y-6" onSubmit={handleRegister}>
              {renderStepContent()}
              {renderDots()}
              <div className="flex justify-between mt-4">
                {currentStep > 1 && (
                  <button
                    type="button"
                    onClick={prevStep}
                    className="group relative w-1/3 flex justify-center py-2 px-4 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Zurück
                  </button>
                )}
                {currentStep < 6 ? (
                  <button
                    type="button"
                    onClick={nextStep}
                    className={`group relative w-1/3 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${currentStep === 1 && 'mx-auto'}`}
                  >
                    Weiter
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="group relative w-1/3 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Fertig
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
        )}
      </div>
    );
  }
  
  export default Login;
  